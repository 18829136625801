import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CardsComponent } from "./cards.component";
import { MatCardModule, MatDividerModule, MatDialogModule, MatSidenavModule, MatIconModule, MatButtonModule, MatChipsModule } from "@angular/material";
import { NgxMasonryModule } from "ngx-masonry";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { CardsDialogComponent } from "./cards-dialog/cards-dialog.component";

@NgModule({
  declarations: [CardsComponent, CardsDialogComponent],
  imports: [
    CommonModule,
    NgxMasonryModule,
    MatCardModule,
    MatDividerModule,
    InfiniteScrollModule,
    MatDialogModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    MatChipsModule
  ],
  exports: [CardsComponent],
  entryComponents: [CardsDialogComponent]
})
export class CardsModule { }
