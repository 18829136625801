import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChildren,
  QueryList
} from "@angular/core";
import { TreeItem } from "./interfaces";
import { indexSignatureToObjectArray } from "./helpers";
import { TreeNodeComponent } from "./tree-node/tree-node.component";
import { FilterService } from "../../shared/filter/filter.service";
import { FilterSection } from "./interfaces/filter-section";
import { FilterStore } from "../../shared/filter/filter.store";
import { AppStore } from "../../shared/app/app.store";
import { autorun } from "mobx";


@Component({
  selector: "tl-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterComponent implements OnInit, AfterViewInit {
  @Input() label: string;
  @Input() filterSections: FilterSection[];
  @ViewChildren(TreeNodeComponent) items: QueryList<TreeNodeComponent>;
  @Output() selectChanged = new EventEmitter<TreeItem[]>();
  // selectedItems: { [id: string]: boolean } = {};
  filterText: string;
  constructor(
    private cdr: ChangeDetectorRef,
    private service: FilterService,
    private filterStore: FilterStore,
    private appStore: AppStore
  ) { }

  ngOnInit() {
    this.service.filterComponent = this;
    autorun(() => {
      const selectedItems = this.appStore.activePage === "features" ? 
                            this.filterStore.selectedFeatures : 
                            this.filterStore.selectedInnovations;
      if (selectedItems) {
        for (const x of selectedItems) {
          for (const section of this.filterSections) {
            const item = section.items.find(i => i.id === x.id);
            if (item && section.page == this.appStore.activePage) {
              item.selected = "selected";
              break;
            }
          }
        }
        for (const item of this.items.toArray()) {
          item.refresh();
        }
      }
    });
  }

  ngAfterViewInit() {
    this.items.changes.subscribe((r) => { this.service.refreshSelectedItems();})
  }

  onChange(treeItem: TreeItem, section: FilterSection): void {
    if (section.selectedItems.find(s => s.id === treeItem.id) &&
        treeItem.selected === "unselected") {
      let topLevelTreeItem = section.items.find(s => (s.subitems) ? s.subitems.filter(subItem => subItem.id === treeItem.id) : null);
      if (topLevelTreeItem && topLevelTreeItem.subitems.filter(s => s.selected === "selected").length === 0) {
        const topLevelIdx = section.selectedItems.findIndex(s => s.id === topLevelTreeItem.id);
        section.selectedItems.splice(topLevelIdx, 1);
        topLevelTreeItem.selected = "unselected";
      }
      const idx = section.selectedItems.findIndex(s => s.id === treeItem.id);
      section.selectedItems.splice(idx, 1);
    } else if (treeItem.selected === "selected" && !section.selectedItems.includes(treeItem)) {
        section.selectedItems.push(treeItem);
        for (const sub of section.items.filter(s => s.subitems)) {
          if (sub.subitems.includes(treeItem)) {
            sub.selected = "partial";
          }
        }
      }
    this.service.refreshSelectedItems(section.items);
    this.selectChanged.emit(section.selectedItems);
    this.cdr.detectChanges();
  }

  getSelectItemCount(): number {
    if (this.appStore.activePage === "features") {
      return this.filterStore.selectedFeatures.length;
    } else if (this.appStore.activePage === "innovations") {
      return this.filterStore.selectedInnovations.length;
    }
  }

  filterTree(value) {
    this.filterText = value;
  }

  resetFilters(): void {
    for (const section of this.filterSections) {
      for (const item of section.items) {
        item.selected = "unselected";
      }
    }
    for (const item of this.items.toArray()) {
      item.selectItem("unselected");
    }
    this.cdr.detectChanges();
  }

  setFilters(params: { filters: TreeItem[]; sectionId: string }[]): void {
    let isNewFilter = false;
    for (const param of params) {
      const section = this.filterSections.find(s => s.id === param.sectionId);

      if (!(section)) {
        break;
      }

      for (const item of section.items) {
        if (item.subitems && item.subitems.length) {
          for (const subitem of item.subitems) {
            if (
              param.filters.find(f => f.id === subitem.id) &&
              subitem.selected === "unselected"
            ) {
              let topLevelTreeItem = section.items.find(s => s.id === item.id);
              topLevelTreeItem.selected = "partial";
              subitem.selected = "selected";
              section.selectedItems.push(subitem);
              isNewFilter = true;
            }
          }
        } else {
          if (
            param.filters.find(f => f.id === item.id) &&
            item.selected === "unselected"
          ) {
            item.selected = "selected";
            section.selectedItems.push(item);
            isNewFilter = true;
          }
        }
      }
      this.selectChanged.emit(section.selectedItems);
    }
    if (isNewFilter) {
      this.service.refreshSelectedItems();
    }
  }
}
