import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from "@angular/core";
import { TreeItem, SelectedStatus } from "../interfaces";
import { FilterService } from "../../../shared/filter/filter.service";

@Component({
  selector: "tl-tree-node",
  templateUrl: "./tree-node.component.html",
  styleUrls: ["./tree-node.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeNodeComponent implements OnInit {
  @Input() id: string;
  @Input() treeItem: TreeItem;
  @Input() filter: string;
  @Input() parent: TreeNodeComponent;
  @Output() selectChanged = new EventEmitter<TreeItem>();
  @ViewChildren(TreeNodeComponent) subItems: QueryList<TreeNodeComponent>;
  get indeterminate(): boolean {
    return this.treeItem.selected === "partial";
  }
  get highlighted(): boolean {
    return !this.treeItem || !this.filter
      ? false
      : this.treeItem.caption
          .toString()
          .toLowerCase()
          .includes(this.filter.toString().toLowerCase());
  }
  private clicked = false;

  constructor(private cdr: ChangeDetectorRef, private service: FilterService) {}

  ngOnInit() {
    this.selectChanged.subscribe((ti: TreeItem) => {
      if (ti.id === this.id) {
        if (this.treeItem.subitems) {
          const selected =
            this.treeItem.selected === "selected" ||
            this.treeItem.selected === "partial"
              ? "selected"
              : "unselected";
          for (const subItem of this.treeItem.subitems) {
            const item = this.subItems.find(i => i.id === subItem.id);
            item.selectItem(selected);
          }
        }
        this.clicked = false;
      } else {
        if (!this.clicked) {
          const selectedCount = this.treeItem.subitems.filter(
            i => i.selected === "selected"
          ).length;
          if (selectedCount === this.treeItem.subitems.length) {
            this.treeItem.selected = "selected";
          } else if (selectedCount === 0) {
            this.treeItem.selected = "unselected";
          } else {
            this.treeItem.selected = "partial";
          }
        }
      }
      this.cdr.detectChanges();
    });
  }

  onClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  onChange(): void {
    this.clicked = true;
    const newSelectedValue =
      this.treeItem.selected === "unselected" ||
      this.treeItem.selected === "partial"
        ? "selected"
        : "unselected";
    this.treeItem.selected = newSelectedValue;
    this.selectChanged.emit(this.treeItem);
    this.cdr.detectChanges();
  }

  onSubChange(subItem: TreeItem): void {
    this.selectChanged.emit(subItem);
  }

  onToggle(): void {
    this.treeItem.expanded = !this.treeItem.expanded;
  }

  selectItem(status: SelectedStatus): void {
    this.treeItem.selected = status;
    this.service.refreshSelectedItems();
    this.selectChanged.emit(this.treeItem);
  }

  isChecked(): boolean {
    const isChecked = this.treeItem && this.treeItem.selected === "selected";
    return isChecked;
  }

  isHidden(): boolean {
    const parentItemVisible =
      !this.parent || !this.filter
        ? false
        : this.parent.treeItem.caption
            .toLowerCase()
            .includes(this.filter.toLowerCase());

    const subItemsVisible = !this.subItems
      ? false
      : this.subItems.filter(i => !i.isHidden()).length > 0;

    return !this.treeItem ||
      !this.filter ||
      this.filter === "" ||
      subItemsVisible ||
      parentItemVisible
      ? false
      : !this.treeItem.caption
          .toLowerCase()
          .includes(this.filter.toLowerCase());
  }

  getParent(): TreeNodeComponent {
    return this;
  }

  getSelectedSubItems(): TreeItem[] {
    const items: TreeItem[] = [];
    for (const item of this.subItems.toArray()) {
      if (item.treeItem.selected === "selected") {
        items.push(item.treeItem);
      }
    }

    return items;
  }

  refresh(): void {
    this.cdr.markForCheck();
  }
}
