import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Innovation } from "../interfaces";

@Injectable({
  providedIn: "root"
})
export class InnovationService {
  constructor(private http: HttpClient) {}

  getData(loadGlobal: boolean): Observable<Innovation[]> {
    if (loadGlobal) {
      return this.http.get("../../../assets/json/versioning-data.json").pipe(
        map((res: any) => {
          return res.global.versioningHistory;
        })
      );
    } else {
      return this.http.get("../../../assets/json/versioning-data.json").pipe(
        map((res: any) => {
          return res.usa.versioningHistory;
        })
      );
    }
  }
}
