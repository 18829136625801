import {
  Component,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  ViewRef
} from "@angular/core";
import { FilterService } from "../../shared/filter/filter.service";
import { TreeItem } from "../filter/interfaces";
import { FilterStore } from "../../shared/filter/filter.store";
import { autorun } from "mobx";
import { AppStore } from "../../shared/app/app.store";

@Component({
  selector: "tl-chip-list",
  templateUrl: "./chip-list.component.html",
  styleUrls: ["./chip-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipListComponent implements AfterViewInit {
  treeItems: TreeItem[];
  visible: boolean;
  @Output() selectChanged = new EventEmitter<TreeItem>();

  constructor(
    private store: FilterStore,
    private service: FilterService,
    private cdr: ChangeDetectorRef,
    private appStore: AppStore
  ) {}

  ngAfterViewInit(): void {
    autorun(() => {
      if (this.appStore.activePage === "features") {
        this.treeItems = this.store.selectedFeatures;
      } else if (this.appStore.activePage === "innovations") {
        this.treeItems = this.store.selectedInnovations;
      }
      
      if (this.cdr && !(this.cdr as ViewRef).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  removeChip(item: TreeItem): void {
    const tagSection = this.service.filterComponent.filterSections.find(
      s => s.id === "Tags"
    );
    const releaseSection = this.service.filterComponent.filterSections.find(
      s => s.id === "Releases");

      if (releaseSection) {
      item.selected = "unselected";
    }

    if (tagSection) {
      const tagItem = tagSection.selectedItems.find(i => i.id === item.id);
      if (tagItem) {
        tagItem.selected = "unselected";
      }
    }
    this.service.removeChip(item.id);
  }

  getHeight(): number {
    const el = document.getElementsByClassName("mat-chip-list-wrapper")[0];
    return parseFloat(window.getComputedStyle(el).height.replace("px", ""));
  }
}
