import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostListener,
  ViewRef
} from "@angular/core";
import { FilterService } from "../shared/filter/filter.service";
import { ChipListComponent } from "../components/chip-list/chip-list.component";
import { Card, Innovation, InnovationCard } from "../shared/interfaces";
import * as _ from "lodash";
import { CardsComponent } from "../components/cards/cards.component";
import { FilterSection } from "../components/filter/interfaces/filter-section";
import { autorun, when } from "mobx";
import { FilterStore } from "../shared/filter/filter.store";
import { InnovationStore } from "../shared/innovations/innovation.store";
import { AppStore } from "../shared/app/app.store";
import { ActivatedRoute, Router } from "@angular/router";
import { TreeItem } from "../components/filter/interfaces";
import { FilterComponent } from "../components/filter/filter.component";

@Component({
  selector: "tl-innovations",
  templateUrl: "./innovations.component.html",
  styleUrls: ["./innovations.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FilterService, CardsComponent]
})
export class InnovationsComponent implements OnInit, AfterViewInit {
  @ViewChild("chips", {static: false}) chips: ElementRef;
  @ViewChild(ChipListComponent, {static: false}) chiplist: ChipListComponent;
  @ViewChild(FilterComponent, {static: false}) filterComponent: FilterComponent;

  showMore: boolean;
  selectedItemCount: number;
  cards: InnovationCard[] = [];
  filteredCards: InnovationCard[] = [];

  filterSections: FilterSection[] = [];
  activeRelease = "";

  stickyNav = false;
  bannerBottom: number;

  @HostListener("window:scroll") scroll() {
    let releases: Element[] = Array.from(
      document.getElementsByClassName("release-element")
    );

    const nav = document.getElementById("filter-nav");
    const banner = document.getElementById("banner");

    this.bannerBottom = banner.getBoundingClientRect().bottom;

    if (nav.getBoundingClientRect().top <= 50) {
      this.stickyNav = true;
    } else {
      this.stickyNav = false;
    }

    releases = releases
      .filter(
        r =>
          r.getBoundingClientRect().bottom &&
          r.getBoundingClientRect().bottom >= 107
      )
      .sort((a, b) => {
        return this.sortReleases(a.id, b.id);
      });

    const el = releases[0];
    if (el) {
      this.innovationStore.setActiveRelease(el.id);
    }
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private innovationStore: InnovationStore,
    private filterStore: FilterStore,
    private appStore: AppStore,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    autorun(() => {
      this.activeRelease = this.innovationStore.activeRelease;
      if (this.cdr && !(this.cdr as ViewRef).destroyed) {
        this.cdr.detectChanges();
      }
    });
    autorun(
      () => {
        this.filteredCards = this.innovationStore.filteredCards;
        this.filterSections = this.innovationStore.filterSections;
        this.innovationStore.resetRelases();
        if (this.cdr && !(this.cdr as ViewRef).destroyed) {
          this.cdr.detectChanges();
        }
      },
      { delay: 10 }
    );
    this.filterStore.setSelectedItems([]);
    this.filterComponent.resetFilters();

    this.activatedRoute.queryParams.subscribe(params => {
      const modules = params["fm"];
      const tags = params["ft"];
      const selectedTags: TreeItem[] = [];
      const selectedModules: TreeItem[] = [];

      const p = [];

      if (modules) {
        if (Array.isArray(modules)) {
          for (const filter of modules) {
            selectedModules.push({
              id: filter,
              caption: filter,
              selected: "selected",
              expanded: false
            });
          }
        } else {
          selectedModules.push({
            id: modules,
            caption: modules,
            selected: "selected",
            expanded: false
          });
        }
        p.push({ filters: selectedModules, sectionId: "Modules" });
      }

      if (tags) {
        if (Array.isArray(tags)) {
          for (const filter of tags) {
            selectedTags.push({
              id: filter,
              caption: filter,
              selected: "selected",
              expanded: false
            });
          }
        } else {
          selectedTags.push({
            id: tags,
            caption: tags,
            selected: "selected",
            expanded: false
          });
        }
        p.push({ filters: selectedTags, sectionId: "Tags" });
      }

      when(
        () => this.innovationStore.filterSections.length > 0,
        () => this.filterComponent.setFilters(p)
      );
    });
    this.appStore.setActivePage("innovations");
  }

  ngAfterViewInit() {
    autorun(() => {
      const chipsEl = this.chips.nativeElement;
      const chipsStyles = window.getComputedStyle(chipsEl);
      this.selectedItemCount = this.filterStore.selectedInnovations.length;
      this.showMore =
        parseFloat(chipsStyles.height.replace("px", "")) <
        this.chiplist.getHeight();
    });
    autorun(() => {
      if (this.innovationStore.selectedTags.length > 0 || this.innovationStore.selectedModules.length > 0) {
        const modules: string[] = [];
        const tags: string[] = [];

        for (const t of this.innovationStore.selectedTags) {
          tags.push(t.id);
        }

        for (const m of this.innovationStore.selectedModules) {
          modules.push(m.id);
        }

        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: { fm: modules, ft: tags },
          queryParamsHandling: "merge"
        });
      }
    });
  }

  onTreeChange(selectedItems: TreeItem[]): void {
    if (selectedItems.find(v => !(parseFloat(v.id) > 0))) {
      this.innovationStore.setSelectedModules(selectedItems);
    }

    const modules: string[] = [];
    const tags: string[] = [];

    for (const t of this.innovationStore.selectedTags) {
      tags.push(t.id);
    }

    for (const m of this.innovationStore.selectedModules) {
      modules.push(m.id);
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { fm: modules, ft: tags },
      queryParamsHandling: "merge"
    });

    this.innovationStore.resetRelases();
    this.cdr.detectChanges();
  }

  getTags(s, c): any {
    if (c.tags) {
      const tags = c.tags.split(",");
      if (tags.length > 1) {
        for (const tag of tags) {
          if (s.id === tag.trim()) {
            return true;
          }
        }
        return false;
      } else {
        return s.id === c.tags;
      }
    }
    return false;
  }

  sortReleases(as: string, bs: string): number {
    const a = as.split(".");
    const b = bs.split(".");

    if (a[0] < b[0]) {
      return 1;
    } else if (a[0] > b[0]) {
      return -1;
    } else {
      if (a[1] < b[1]) {
        return 1;
      } else if (a[1] > b[1]) {
        return -1;
      } else {
        return 0;
      }
    }
  }
}
