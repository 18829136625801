import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ElementRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostListener,
  ViewRef
} from "@angular/core";
import { FilterService } from "../shared/filter/filter.service";
import { ChipListComponent } from "../components/chip-list/chip-list.component";
import { Card } from "../shared/interfaces";
import * as _ from "lodash";
import { CardsComponent } from "../components/cards/cards.component";
import { FilterSection } from "../components/filter/interfaces/filter-section";
import { FeatureStore } from "../shared/feature/feature.store";
import { autorun, when } from "mobx";
import { FilterStore } from "../shared/filter/filter.store";
import { AppStore } from "../shared/app/app.store";
import { TreeItem } from "../components/filter/interfaces";
import { Router, ActivatedRoute } from "@angular/router";
import { FilterComponent } from "../components/filter/filter.component";

@Component({
  selector: "tl-features",
  templateUrl: "./features.component.html",
  styleUrls: ["./features.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FilterService, CardsComponent]
})
export class FeaturesComponent implements OnInit, AfterViewInit {
  @ViewChild("chips", {static: false}) chips: ElementRef;
  @ViewChild(ChipListComponent, {static: false}) chiplist: ChipListComponent;
  @ViewChild(FilterComponent, {static: false}) filterComponent: FilterComponent;

  showMore: boolean;
  selectedItemCount: number;
  cards: Card[] = [];
  filteredCards: Card[] = [];

  filterSections: FilterSection[] = [];
  activeRelease = "";

  stickyNav = false;
  bannerBottom: number;

  @HostListener("window:scroll") scroll() {
    let releases: Element[] = Array.from(
      document.getElementsByClassName("release-element")
    );

    const nav = document.getElementById("filter-nav");
    const banner = document.getElementById("banner");

    this.bannerBottom = banner.getBoundingClientRect().bottom;

    if (nav.getBoundingClientRect().top <= 50) {
      this.stickyNav = true;
    } else {
      this.stickyNav = false;
    }

    releases = releases
      .filter(
        r =>
          r.getBoundingClientRect().bottom &&
          r.getBoundingClientRect().bottom >= 107
      )
      .sort((a, b) => {
        return this.sortReleases(a.id, b.id);
      });

    const el = releases[0];
    if (el) {
      this.featureStore.setActiveRelease(el.id);
    }
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private featureStore: FeatureStore,
    private filterStore: FilterStore,
    private appStore: AppStore,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    autorun(() => {
      this.activeRelease = this.featureStore.activeRelease;
      if (this.cdr && !(this.cdr as ViewRef).destroyed) {
        this.cdr.detectChanges();
      }
    });
    autorun(() => {
      this.filteredCards = this.featureStore.filteredCards;
      this.filterSections = this.featureStore.filterSections;
      this.featureStore.resetRelases();
      if (this.cdr && !(this.cdr as ViewRef).destroyed) {
        this.cdr.detectChanges();
      }
    }, { delay: 10 });
    this.filterStore.setSelectedItems([]);
    this.filterComponent.resetFilters();

    this.activatedRoute.queryParams.subscribe(params => {
      const versions = params["fr"];
      const modules = params["fm"];
      const selectedVersions: TreeItem[] = [];
      const selectedModules: TreeItem[] = [];

      const p = [];

      if (versions) {
        if (Array.isArray(versions)) {
          for (const filter of versions) {
            selectedVersions.push({
              id: filter,
              caption: filter,
              selected: "selected",
              expanded: false
            });
          }
        } else {
          selectedVersions.push({
            id: versions,
            caption: versions,
            selected: "selected",
            expanded: false
          });
        }
        p.push({ filters: selectedVersions, sectionId: "Releases" });
      }

      if (modules) {
        if (Array.isArray(modules)) {
          for (const filter of modules) {
            selectedModules.push({
              id: filter,
              caption: filter,
              selected: "selected",
              expanded: false
            });
          }
        } else {
          selectedModules.push({
            id: modules,
            caption: modules,
            selected: "selected",
            expanded: false
          });
        }
        p.push({ filters: selectedModules, sectionId: "Modules" });
      }

      when(
        () => this.featureStore.filterSections.length > 0,
        () => this.filterComponent.setFilters(p)
      );
    });

    this.appStore.setActivePage("features");
  }

  ngAfterViewInit(): void {
    autorun(() => {
        const chipsEl = this.chips.nativeElement;
        const chipsStyles = window.getComputedStyle(chipsEl);
        this.selectedItemCount = this.filterStore.selectedFeatures.length;
        this.showMore =
          parseFloat(chipsStyles.height.replace("px", "")) <
          this.chiplist.getHeight();
      }
    );
    autorun(() => {
      if (this.featureStore.selectedVersions.length > 0 || this.featureStore.selectedModules.length > 0) {
        const versions: string[] = [];
        const modules: string[] = [];

        for (const version of this.featureStore.selectedVersions) {
          versions.push(version.id);
        }

        for (const m of this.featureStore.selectedModules) {
          modules.push(m.id);
        }

        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: { fr: versions, fm: modules },
          queryParamsHandling: "merge"
        });
      }
    });
  
  }

  onTreeChange(selectedItems: TreeItem[]): void {
    if (selectedItems.find(i => i.id.includes("."))) {
      this.featureStore.setSelectedVersions(selectedItems);
    } else if (selectedItems.find(v => !(parseFloat(v.id) > 0))) {
      this.featureStore.setSelectedModules(selectedItems);
    }

    const versions: string[] = [];
    const modules: string[] = [];

    for (const version of this.featureStore.selectedVersions) {
      versions.push(version.id);
    }

    for (const m of this.featureStore.selectedModules) {
      modules.push(m.id);
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { fr: versions, fm: modules },
      queryParamsHandling: "merge"
    });

    this.featureStore.resetRelases();
    this.cdr.detectChanges();
  }

  sortReleases(as: string, bs: string): number {
    const a = as.split(".");
    const b = bs.split(".");
    const a0 = parseInt(a[0], 10);
    const a1 = parseInt(a[1], 10);
    const b0 = parseInt(b[0], 10);
    const b1 = parseInt(b[1], 10);

    if (a0 < b0) {
      return 1;
    } else if (a0 > b0) {
      return -1;
    } else {
      if (a1 < b1) {
        return 1;
      } else if (a1 > b1) {
        return -1;
      } else {
        return 0;
      }
    }
  }
}
