import { Injectable } from "@angular/core";
import { observable, action, computed } from "mobx";
import { Card, Feature } from "../interfaces";
import { TreeItem } from "../../components/filter/interfaces";
import * as _ from "lodash";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FeatureService } from "./feature.service";
import { FilterSection } from "../../components/filter/interfaces/filter-section";
import { SafeHtml, DomSanitizer } from "@angular/platform-browser";

@Injectable({
  providedIn: "root"
})
export class FeatureStore {
  constructor(
    private featureService: FeatureService,
    private sanitizer: DomSanitizer
  ) {}

  @observable releaseCount = 5;
  @observable filterSections: FilterSection[] = [];
  @observable cards: Card[] = [];
  @observable activeRelease: string;
  @observable releaseToScroll: string;
  @observable scrollElement: any;
  @observable selectedVersions: TreeItem[] = [];
  @observable selectedModules: TreeItem[] = [];

  @computed get filteredCards(): Card[] {
    let cards: Card[] = Object.assign(this.cards);

    if (this.selectedModules.length) {
      cards = cards.filter(c =>
        this.selectedModules.find(s => s.id === c.module)
      );
    }
    if (this.selectedVersions.length) {
      cards = cards.filter(c =>
        this.selectedVersions.find(s => s.id === c.version)
      );
    }

    return cards;
  }

  @action addCards(features: Feature[]): void {
    const cards: Card[] = [];
    const treeItems: TreeItem[] = [];
    const treeItemsModules: TreeItem[] = [];
    let t: TreeItem = {
      id: "",
      caption: "",
      selected: "unselected",
      expanded: false,
      subitems: []
    };

    for (const feature of features) {
      const description = this.getDescription(feature.id);
      cards.push({
        title: feature.name,
        description: description,
        version: feature.release,
        module: feature.module,
        implementation: feature.fa_configuration,
        id: feature.id,
        hidden: false
      });
      
      let mainLevel = "20" + ('00' + feature.release.split(".")[0]).slice(-2);
      if (!treeItems.find(item => item.id === mainLevel)) {
        t = {
          id: `20${('00' + feature.release.split(".")[0]).slice(-2)}`,
          caption: `20${('00' + feature.release.split(".")[0]).slice(-2)}`,
          selected: "unselected",
          expanded: false,
          subitems: []
        };
        treeItems.push(t);
      }
      
      if (!treeItems.find(main => main.subitems.find(sub => sub.id === feature.release))) {
        treeItems.find(mainItem => mainItem.id === mainLevel).subitems.push(
          {
            id: feature.release,
            caption: feature.release,
            selected: "unselected",
            expanded: false
          });
      }
    }

    let modules = _.groupBy(features, "module");

    for (const m of Object.keys(modules)) {
      const moduleSplit = m.split(",");

      for (const moduleName of moduleSplit) {
        const name = moduleName.trim();

        if (!treeItemsModules.find(i => i.caption === name)) {
          treeItemsModules.push({
            id: name,
            caption: name,
            selected: "unselected",
            expanded: false
          });
        }
      }
    }

    this.cards = cards;
    for (const item of treeItems) {
      item.subitems = item.subitems.sort((a: TreeItem, b: TreeItem) =>
        this.sortItems(a, b)
      );
    }
    this.filterSections.push({
      id: "Releases",
      page: "features",
      caption: "Releases",
      selectedItems: [],
      expanded: false,
      items: treeItems
        .sort((a: TreeItem, b: TreeItem) => this.sortItems(a, b))
        
    });

    const moduleItems = treeItemsModules.sort((a: TreeItem, b: TreeItem) => {
      if (a.caption < b.caption) {
        return -1;
      } else if (a.caption > b.caption) {
        return 1;
      } else {
        return 0;
      }
    });

    this.filterSections.push({
      id: "Modules",
      page: "features",
      caption: "Modules",
      selectedItems: [],
      expanded: false,
      items: moduleItems
    });
  }
  @action addRelease(): void {
    this.releaseCount++;
  }

  @action resetRelases(): void {
    this.releaseCount = 5;
  }

  @action setActiveRelease(release: string): void {
    this.activeRelease = release;
  }

  @action setReleaseToScroll(release: string): void {
    this.releaseToScroll = release;
  }

  @action setScrollElement(element: any): void {
    this.scrollElement = element;
  }

  @action setSelectedVersions(selectedVersions: TreeItem[]): void {
    this.selectedVersions = selectedVersions;
  }
  @action setSelectedModules(selectedModules: TreeItem[]): void {
    this.selectedModules = selectedModules;
  }

  private getDescription(id: string): Observable<SafeHtml> {
    return this.featureService
      .getDescription(id)
      .pipe(
        map(description => this.sanitizer.bypassSecurityTrustHtml(description))
      );
  }
  
  private sortItems(a: TreeItem, b: TreeItem): number {

    const rev_a = a.caption.split(".")[2] ? ("00" + a.caption.split(".")[2]).slice(-2) : "00";
    const rev_b = b.caption.split(".")[2] ? ("00" + b.caption.split(".")[2]).slice(-2) : "00";

    const a_full = parseInt((a.caption.split(".")[0]) + ("00" + a.caption.split(".")[1]).slice(-2) + rev_a);
    const b_full = parseInt((b.caption.split(".")[0]) + ("00" + b.caption.split(".")[1]).slice(-2) + rev_b);
    
    if (a_full > b_full) {
      return -1;
    } else if (a_full < b_full) {
      return 1;
    } else {
      return 0;
    }
  }
}
