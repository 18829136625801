import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { HomeService } from "./home.service";
import { FrontpageItem } from "..//shared/interfaces";

@Component({
  selector: "tl-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit {
  items: FrontpageItem[] = [];
  constructor(private service: HomeService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.service.getData().subscribe(frontpageItems => {
      this.items = frontpageItems;
      this.cdr.markForCheck();
    });
  }
}
