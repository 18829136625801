import { Injectable } from "@angular/core";
import { observable, action } from "mobx";
import { Card, InnovationCard } from "../interfaces";
import { FeatureStore } from "../feature/feature.store";
import { InnovationStore } from "../innovations/innovation.store";
import { FilterStore } from "../filter/filter.store";

@Injectable({
  providedIn: "root"
})
export class AppStore {
  @observable activePage: string;
  @observable selectedCard: Card | InnovationCard;

  constructor(
    private featureStore: FeatureStore,
    private innovationStore: InnovationStore,
    private filterStore: FilterStore
  ) {}

  @action setActivePage(page: string): void {
    this.activePage = page;
    this.filterStore.activePage = this.activePage;
  }

  @action setSelectedCard(card: Card | InnovationCard): void {
    this.selectedCard = card;
  }
  @action nextCard(): void {
    if (this.activePage === "features") {
      const cards = this.featureStore.filteredCards;

      const idx = cards.findIndex(c => c.id === this.selectedCard.id);
      if (idx === cards.length - 1) {
        this.selectedCard = cards[0];
      } else {
        this.selectedCard = cards[idx + 1];
      }
    } else {
      const cards = this.innovationStore.filteredCards;

      const idx = cards.findIndex(c => c.id === this.selectedCard.id);
      if (idx === cards.length - 1) {
        this.selectedCard = cards[0];
      } else {
        this.selectedCard = cards[idx + 1];
      }
    }
  }
  @action prevCard(): void {
    if (this.activePage === "features") {
      const cards = this.featureStore.filteredCards;

      const idx = cards.findIndex(c => c.id === this.selectedCard.id);
      if (idx === 0) {
        this.selectedCard = cards[cards.length - 1];
      } else {
        this.selectedCard = cards[idx - 1];
      }
    } else {
      const cards = this.innovationStore.filteredCards;

      const idx = cards.findIndex(c => c.id === this.selectedCard.id);
      if (idx === 0) {
        this.selectedCard = cards[cards.length - 1];
      } else {
        this.selectedCard = cards[idx - 1];
      }
    }
  }
}
