import { Injectable } from "@angular/core";
import { FilterComponent } from "../../components/filter/filter.component";
import { TreeItem } from "../../components/filter/interfaces";
import { indexSignatureToKeyArray } from "../../components/filter/helpers";
import { TreeNodeComponent } from "../../components/filter/tree-node/tree-node.component";
import { FilterStore } from "./filter.store";

@Injectable({
  providedIn: "root"
})
export class FilterService {
  filterComponent: FilterComponent;

  constructor(private store: FilterStore) {}

  refreshSelectedItems(selectedItems?: TreeItem[]): void {
    let items: TreeItem[] = [];
    if (!selectedItems) {
      for (const section of this.filterComponent.filterSections) {
        for (const item of section.items.filter(i => i.selected !== "unselected" && !items.includes(i))) { 
          if (item.subitems && item.subitems.filter(i => i.selected === "selected").length > 0) {
            items.push(item);
          } else if (item.selected === "selected" && !item.subitems){
            items.push(item);
          }
        }
      }
    } else {
      items = selectedItems.filter(i => i.selected !== "unselected");
      /* ADD & KEEP TOP LEVELS IN STORE. */
      for (const section of this.filterComponent.filterSections) {
        for (const item of section.items.filter(i => i.selected !== "unselected" && !items.includes(i))) {
          if (item.subitems && item.subitems.filter(i => i.selected === "selected").length > 0) {
            items.push(item);
          } else if (!item.subitems){
            items.push(item);
          }
        }
      }
    }
    this.store.setSelectedItems(items);
  }

  removeChip(id: string): void {
    const node = this.findTreeNodeComponent(
      this.filterComponent.items.toArray(),
      id
    );
    node.selectItem("unselected");
  }

  private findItem(treeitems: TreeItem[], id: string): TreeItem {
    let item: TreeItem;
    for (const i of treeitems) {
      if (i.id === id) {
        item = i;
        break;
      }
      if (!item && i.subitems) {
        item = this.findItem(i.subitems, id);
        if (item) {
          break;
        }
      }
    }
    return item;
  }

  private findTreeNodeComponent(
    nodes: TreeNodeComponent[],
    id: string
  ): TreeNodeComponent {
    let result: TreeNodeComponent;
    for (const node of nodes) {
      if (node.id === id) {
        result = node;
        break;
      }
      if (!result && node.subItems && node.subItems.toArray().length) {
        result = this.findTreeNodeComponent(node.subItems.toArray(), id);
        if (result) {
          break;
        }
      }
    }
    return result;
  }
}
