import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { FeaturesComponent } from './features/features.component';
import { InnovationsComponent } from './innovations/innovations.component';
// import { TerminologyComponent } from './terminology';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'innovations', component: InnovationsComponent },
  { path: 'features', component: FeaturesComponent },
  // { path: 'terminology', component: TerminologyComponent },
  // { path: 'terminology/:term', component: TerminologyComponent }
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
