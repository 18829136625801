import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { FrontpageItem } from "../shared/interfaces";

@Injectable({
  providedIn: "root"
})
export class HomeService {
  constructor(private http: HttpClient) {}

  getData(): Observable<FrontpageItem[]> {
    return this.http
      .get("../../../assets/json/frontpage.json")
      .pipe(map((res: any) => res));
  }
}
