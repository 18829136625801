import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FilterComponent } from "./filter.component";
import {
  MatButtonModule,
  MatTreeModule,
  MatMenuModule,
  MatIconModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatBadgeModule,
  MatInputModule,
  MatChipsModule,
  MatExpansionModule
} from "@angular/material";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TreeNodeComponent } from "./tree-node/tree-node.component";

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatTreeModule,
    MatMenuModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatBadgeModule,
    MatInputModule,
    MatChipsModule,
    MatExpansionModule
  ],
  declarations: [FilterComponent, TreeNodeComponent],
  exports: [FilterComponent]
})
export class FilterModule {}
