import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InnovationsComponent } from './innovations.component';
import { BannerModule } from '../components/banner/banner.module';
import { FilterModule } from '../components/filter/filter.module';
import { ChipListModule } from '../components/chip-list/chip-list.module';
import { MatTabsModule, MatCardModule } from '@angular/material';
import { NgxMasonryModule } from 'ngx-masonry';
import { CardsModule } from '../components/cards/cards.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [InnovationsComponent],
  imports: [
    CommonModule,
    BannerModule,
    CommonModule,
    BannerModule,
    FilterModule,
    ChipListModule,
    MatTabsModule,
    NgxMasonryModule,
    MatCardModule,
    CardsModule,
    InfiniteScrollModule
  ]
})
export class InnovationsModule { }
