import { Component, OnInit, Inject } from "@angular/core";
import { Card, InnovationCard } from "../../../shared/interfaces";
import { MatDialogRef } from "@angular/material";
import { AppStore } from "../../../shared/app/app.store";
import { autorun } from "mobx";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "tl-cards-dialog",
  templateUrl: "./cards-dialog.component.html",
  styleUrls: ["./cards-dialog.component.scss"]
})
export class CardsDialogComponent implements OnInit {
  selectedCard: any;
  counterGradientArray = 0;

  constructor(
    public dialogRef: MatDialogRef<CardsDialogComponent>,
    private appStore: AppStore,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    autorun(() => {
      this.selectedCard = this.appStore.selectedCard;
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { card: this.selectedCard.id },
        queryParamsHandling: "merge"
      });
    });
  }

  getCardTitle(card: Card | InnovationCard): string {
    return card.hasOwnProperty("title")
      ? (card as Card).title
      : (card as InnovationCard).cardTitle;
  }
  getCardModule(card: Card | InnovationCard): string {
    return card.hasOwnProperty("title")
      ? (card as Card).module
      : (card as InnovationCard).category;
  }
  getCardVersion(card: Card | InnovationCard): string {
    if (card.hasOwnProperty("title")) {
      return (card as Card).version;
    } else {
      const d = card.version.split("_");
      return d[1];
    }
  }

  getCardYear(card: Card | InnovationCard): string {
    if (!card.hasOwnProperty("title")) {
      const d = card.version.split("_");
      return d[0];
    }
  }
  showYear(card: Card | InnovationCard): boolean {
    return !card.hasOwnProperty("title");
  }
  setImageGradientBG(card: InnovationCard): string {
    const gradientCollection: string[] = [
      "gradient-1",
      "gradient-2",
      "gradient-3",
      "gradient-4",
      "gradient-5"
    ];

    if (card.gradientColor === undefined) {
      card.gradientColor = gradientCollection[this.counterGradientArray];
      this.counterGradientArray++;
    }

    if (this.counterGradientArray === gradientCollection.length) {
      this.counterGradientArray = 0;
    }
    return card.gradientColor;
  }

  next(): void {
    this.appStore.nextCard();
  }

  prev(): void {
    this.appStore.prevCard();
  }

  close(): void {
    this.dialogRef.close();
  }
}
