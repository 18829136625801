import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Feature } from "../interfaces";
import indexFileJson from '../../../assets/json/features/data.json';
@Injectable({
  providedIn: "root"
})
export class FeatureService {
  constructor(private http: HttpClient) {}
  async getData(): Promise<Feature[]> {
    let features: Feature[] = [];
    for (let dataJson of indexFileJson) {
      let featureSet = await this.http
        .get(dataJson)
        .pipe(map((res: any) => res.features)).toPromise();
      features = features.concat(featureSet);
    }
    return features;
  }

  getDescription(id: string): Observable<any> {
    return this.http
      .get(`/assets/json/features/FeatureDescriptions/${id}/FeatureDescription.html`, {
        responseType: "text"
      })
      .pipe(map(res => res));
  }
}
