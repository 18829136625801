import { Injectable } from "@angular/core";
import { Card, InnovationCard } from "src/app/shared/interfaces";

@Injectable({
  providedIn: "root"
})
export class CardService {
  selectedCard: Card | InnovationCard;

  constructor() {}
}
