import { Component, OnInit, HostListener, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FeatureService } from "./shared/feature/feature.service";
import { FeatureStore } from "./shared/feature/feature.store";
import { InnovationService } from "./shared/innovations/innovation.service";
import { InnovationStore } from "./shared/innovations/innovation.store";
import { MatDrawer } from "@angular/material";

@Component({
  selector: "tl-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  @ViewChild(MatDrawer, {static: false}) drawer: MatDrawer;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private featureService: FeatureService,
    private innovationService: InnovationService,
    private featureStore: FeatureStore,
    private innovationStore: InnovationStore
  ) {}
  @HostListener('window:scroll', ['$event'])
  onscroll(event: any) {
    this.drawer.close();
  }
  loadGlobal = true;

  async ngOnInit() {

    this.activatedRoute.queryParams.subscribe(params => {
      const countryParam = params['country'];

      if (countryParam) {
        this.loadGlobal = false;
        this.router.navigate(['/innovations'], { queryParams: { country: countryParam}, queryParamsHandling: "merge" });
      }
    });

    await this.featureService.getData().then(features => {
      this.featureStore.addCards(features)
    });
    this.innovationService.getData(this.loadGlobal).subscribe(innovations => {
      this.innovationStore.addCards(innovations);
    });
  }

  isActiveRoute(route: string): boolean {
    return this.router.isActive(route, false);
  }

  clickRoute(route: string): void {
    this.router.navigateByUrl(route);
  }
}
