import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ChipListComponent } from "./chip-list.component";
import { MatChipsModule, MatIconModule, MatButtonModule } from "@angular/material";

@NgModule({
  declarations: [ChipListComponent],
  imports: [CommonModule, MatChipsModule, MatIconModule, MatButtonModule],
  exports: [ChipListComponent]
})
export class ChipListModule {}
