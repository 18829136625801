import { Injectable } from "@angular/core";
import { observable, action, computed } from "mobx";
import { Innovation, InnovationCard } from "../interfaces";
import * as _ from "lodash";
import { FilterSection } from "../../components/filter/interfaces/filter-section";
import { TreeItem } from "../../components/filter/interfaces";
@Injectable({
  providedIn: "root"
})
export class InnovationStore {
  constructor() {}

  @observable releaseCount = 5;
  @observable filterSections: FilterSection[] = [];
  @observable cards: InnovationCard[] = [];
  @observable activeRelease: string;
  @observable releaseToScroll: string;
  @observable scrollElement: any;
  @observable selectedTags: TreeItem[] = [];
  @observable selectedModules: TreeItem[] = [];

  @computed get filteredCards(): InnovationCard[] {
    
    let cards: InnovationCard[] = Object.assign(this.cards);

    if (this.selectedModules.length) {
      cards = cards.filter(c =>
        this.selectedModules.find(s => s.id === c.category)
      );
    }
    if (this.selectedTags.length) {
      cards = cards.filter(c =>
        this.selectedTags.find(s => s.id === c.version)
      );
    }

    return cards;
  }

  @action addCards(innovations: Innovation[]): void {
    const cards: InnovationCard[] = [];
    const treeItemsModules: TreeItem[] = [];
    const treeItemsTags: TreeItem[] = [];

    for (const innovation of innovations) {
      const tempCards = Object.assign(innovation.cards);
      for (const card of tempCards) {
        card.version = `${card.year}_${card.quarter}`;
      }
      cards.push(...tempCards);
    }
    this.cards = cards;

    const modules = _.groupBy(cards, "category");
    const tags = _.groupBy(cards, "tags");

    for (const m of Object.keys(modules)) {
      const moduleSplit = m.split(",");

      for (const moduleName of moduleSplit) {
        const name = moduleName.trim();

        if (!treeItemsModules.find(i => i.caption === name)) {
          treeItemsModules.push({
            id: name,
            caption: name,
            selected: "unselected",
            expanded: false
          });
        }
      }
    }

    for (const t of Object.keys(tags)) {
      const tagSplit = t.split(",");

      for (const tagName of tagSplit) {
        const name = tagName.trim();

        if (
          !treeItemsTags.find(i => i.caption === name) &&
          name !== "undefined"
        ) {
          treeItemsTags.push({
            id: name,
            caption: name,
            selected: "unselected",
            expanded: false
          });
        }
      }
    }

    const moduleItems = treeItemsModules.sort((a: TreeItem, b: TreeItem) => {
      if (a.caption < b.caption) {
        return -1;
      } else if (a.caption > b.caption) {
        return 1;
      } else {
        return 0;
      }
    });

    this.filterSections.push({
      id: "Modules",
      page: "innovations",
      caption: "Modules",
      selectedItems: [],
      expanded: true,
      items: moduleItems
    });
    if (treeItemsTags.length > 0) {
      this.filterSections.push({
        id: "Tags",
        page: "innovations",
        caption: "Tags",
        selectedItems: [],
        expanded: true,
        items: treeItemsTags
      });
    }
  }
  @action addRelease(): void {
    this.releaseCount++;
  }

  @action resetRelases(): void {
    this.releaseCount = 5;
  }

  @action setActiveRelease(release: string): void {
    this.activeRelease = release;
  }

  @action setReleaseToScroll(release: string): void {
    this.releaseToScroll = release;
  }

  @action setScrollElement(element: any): void {
    this.scrollElement = element;
  }
  @action setSelectedModules(selectedModules: TreeItem[]): void {
    this.selectedModules = selectedModules;
  }

  private sortItems(a: TreeItem, b: TreeItem): number {
    if (
      parseInt(a.caption.split(".")[1], 10) <
      parseInt(b.caption.split(".")[1], 10)
    ) {
      return -1;
    } else if (
      parseInt(a.caption.split(".")[1], 10) >
      parseInt(b.caption.split(".")[1], 10)
    ) {
      return 1;
    } else {
      return 0;
    }
  }
}
