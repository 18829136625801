import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostListener,
  ViewChild,
  ViewRef
} from "@angular/core";
import { Card, InnovationCard } from "../../shared/interfaces";
import * as _ from "lodash";
import { MatDialog } from "@angular/material";
import { CardsDialogComponent } from "./cards-dialog/cards-dialog.component";
import { CardService } from "./card.service";
import { FeatureStore } from "../../shared/feature/feature.store";
import { autorun } from "mobx";
import { AppStore } from "../../shared/app/app.store";
import { InnovationStore } from "../../shared/innovations/innovation.store";
import { ActivatedRoute, Router } from "@angular/router";
import { FilterComponent } from "../filter/filter.component";
import { FilterService } from "../../shared/filter/filter.service";
import { FilterStore } from "../../shared/filter/filter.store";

@Component({
  selector: "tl-cards",
  templateUrl: "./cards.component.html",
  styleUrls: ["./cards.component.scss"],
  providers: [CardService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardsComponent implements OnInit {
  @Input() cards: Card[] | InnovationCard[] = [];

  releaseCount: number;

  activeRelease: string;
  stickyNav = false;
  bannerBottom: number;
  
  private monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  counterGradientArray = 0;

  @HostListener("window:scroll") scroll() {
    const nav = document.getElementById("filter-nav");
    const banner = document.getElementById("banner");

    this.bannerBottom = banner.getBoundingClientRect().bottom;

    if (window.pageYOffset >= nav.offsetTop) {
      this.stickyNav = true;
    } else {
      this.stickyNav = false;
    }
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private store: FeatureStore,
    private innovationStore: InnovationStore,
    private filterService: FilterService,
    private cardService: CardService,
    private filterStore: FilterStore,
    private appStore: AppStore,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    autorun(() => {
      this.releaseCount =
        this.appStore.activePage === "features"
          ? this.store.releaseCount
          : this.innovationStore.releaseCount;
    });
    autorun(() => {
      this.activeRelease =
        this.appStore.activePage === "features"
          ? this.store.activeRelease
          : this.innovationStore.activeRelease;
    });
    autorun(() => {
      const release =
        this.appStore.activePage === "features"
          ? this.store.releaseToScroll
          : this.innovationStore.releaseToScroll;
      if (release) {
        const releases: any[] = Array.from(
          document.getElementsByClassName("release-element")
        );

        let element = releases.find(r => r.id === release);
        let i = 0;

        element = this.findRelease(release, i);
      }
    });
    autorun(() => {
      const element =
        this.appStore.activePage === "features"
          ? this.store.scrollElement
          : this.innovationStore.scrollElement;
      if (element) {
        const pos = element.offsetTop + element.offsetHeight / 2;
        window.scrollTo({ top: pos, left: 0, behavior: "smooth" });
      }
    });

    this.activatedRoute.queryParams.subscribe(params => {
      if (!this.cards.length) {
        setTimeout(() => {
          const card = params["card"];

          let idx;
          if (this.cards[0] && this.cards[0].hasOwnProperty("title")) {
            idx = (this.cards as Card[]).findIndex(c => c.id === card);
          } else {
            idx = (this.cards as InnovationCard[]).findIndex(
              c => c.id === card
            );
          }
          if (card && idx >= 0) {
            this.openDialog(this.cards[idx]);
          }
        }, 1000);
      }
    });
  }

  refresh(): void {
    this.cdr.detectChanges();
  }

  getReleases(): string[] {
    const releases = _.groupBy(this.cards, "version");
    return Object.keys(releases).sort((a, b) => this.sortReleases(a, b));
  }

  getCardsByRelease(release: string): Card[] | InnovationCard[] {
    return this.cards[0].hasOwnProperty("title")
      ? (this.cards as Card[]).filter(c => c.version === release && !c.hidden)
      : (this.cards as InnovationCard[]).filter(c => c.version === release);
  }

  getYear(release: string): string {
    if (release.includes(".")) {
      const d = release.split(".");
      const date = new Date(parseInt(d[0], 10) + 2000, parseInt(d[1], 10) - 1);
      return date.getFullYear().toString();
    } else {
      const d = release.split("_");
      return d[0];
    }
  }

  getMonth(release: string): string {
    if (release.includes(".")) {
      const d = release.split(".");
      const date = new Date(parseInt(d[0], 10) + 2000, parseInt(d[1], 10) - 1);
      return this.monthNames[date.getMonth()];
    } else {
      const d = release.split("_");
      return d[1];
    }
  }

  clickDialog(card: Card | InnovationCard): void {
    this.openDialog(card);
  }

  openDialog(card: Card | InnovationCard) {
    this.cardService.selectedCard = card;
    this.appStore.selectedCard = card;
    const dialogRef = this.dialog.open(CardsDialogComponent, {});
    dialogRef.afterClosed().subscribe(() => {
      const url: string = this.router.url.substring(
        0,
        this.router.url.indexOf("?")
      );
      this.router.navigate([url], { queryParams: { card: null }, queryParamsHandling: "merge" });
    });
  }

  onScroll(): void {
    this.appStore.activePage === "features"
      ? this.store.addRelease()
      : this.innovationStore.addRelease();
  }

  getDateTop(release: string): string {
    if (this.activeRelease === release) {
      if (!this.stickyNav) {
        return "unset";
      } else {
        if (this.bannerBottom > 50) {
          return `${this.bannerBottom + 57}px`;
        } else {
          return "108px";
        }
      }
    } else {
      return "unset";
    }
  }
  scrollTo(release: string): void {
    this.appStore.activePage === "features"
      ? this.store.setReleaseToScroll(release)
      : this.innovationStore.setReleaseToScroll(release);
  }
  getCardTitle(card: Card | InnovationCard): string {
    return card.hasOwnProperty("title")
      ? (card as Card).title
      : (card as InnovationCard).cardTitle;
  }
  getCardModule(card: Card | InnovationCard): string {
    return card.hasOwnProperty("title")
      ? (card as Card).module
      : (card as InnovationCard).category;
  }
  getCardVersion(card: Card | InnovationCard): string {
    if (card.hasOwnProperty("title")) {
      return (card as Card).version;
    } else {
      const d = card.version.split("_");
      return d[1];
    }
  }
  getCardTags(card: Card | InnovationCard): any {
    if (card.tags) {
      const t = card.tags.split(",");
      return t;
    }
  }
  showYear(card: Card | InnovationCard): boolean {
    return !card.hasOwnProperty("title");
  }
  getCardYear(card: Card | InnovationCard): string {
    if (!card.hasOwnProperty("title")) {
      const d = card.version.split("_");
      return d[0];
    }
  }
  setImageGradientBG(card: InnovationCard): string {
    const gradientCollection: string[] = [
      "gradient-1",
      "gradient-2",
      "gradient-3",
      "gradient-4",
      "gradient-5"
    ];

    if (card.gradientColor === undefined) {
      card.gradientColor = gradientCollection[this.counterGradientArray];
      this.counterGradientArray++;
    }

    if (this.counterGradientArray === gradientCollection.length) {
      this.counterGradientArray = 0;
    }
    return card.gradientColor;
  }
  getTags(card: InnovationCard): string[] {
    if (card.tags) {
      const tags = card.tags.split(",");
      for (const tag of tags) {
        tag.trim();
      }
      return tags;
    }
    return [];
  }
  clickTag(tag: string): void {
    tag = tag.trim();
    if (!this.filterStore.selectedItems.find(t => t.id === tag)) {
      this.filterService.filterComponent.onChange(
        {
          id: tag,
          caption: tag,
          selected: "selected",
          expanded: false,
          subitems: []
        },
        this.innovationStore.filterSections.find(s => s.id === "Tags")
      );
    }
  }
  private findRelease(release: string, i: number): void {
    setTimeout(() => {
      this.appStore.activePage === "features"
        ? this.store.addRelease()
        : this.innovationStore.addRelease();
      if (this.cdr && !(this.cdr as ViewRef).destroyed) {
          this.cdr.detectChanges();
      }

      const releases = Array.from(
        document.getElementsByClassName("release-element")
      );
      const element = releases.find(r => r.id === release);
      if (!element && i < 20) {
        this.findRelease(release, i + 1);
      } else {
        this.appStore.activePage === "features"
          ? this.store.setScrollElement(element)
          : this.innovationStore.setScrollElement(element);
      }
    });
  }
  private sortReleases(as: string, bs: string): number {
    const a = as.split(".");
    const b = bs.split(".");
    const a0 = parseInt(a[0], 10);
    const a1 = parseInt(a[1], 10);
    const b0 = parseInt(b[0], 10);
    const b1 = parseInt(b[1], 10);

    if (a0 < b0) {
      return 1;
    } else if (a0 > b0) {
      return -1;
    } else {
      if (a1 < b1) {
        return 1;
      } else if (a1 > b1) {
        return -1;
      } else {
        return 0;
      }
    }
  }
}
