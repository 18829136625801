import { Injectable, Input } from "@angular/core";
import { observable, action, computed } from "mobx";
import { TreeItem } from "../../components/filter/interfaces";
import * as _ from "lodash";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({
  providedIn: "root"
})
export class FilterStore {

  constructor() {}

  @Input() activePage: string;
  @observable selectedFeatures: TreeItem[] = [];
  @observable selectedInnovations: TreeItem[] = [];

  @action setSelectedItems(items: TreeItem[]): void {
    if (this.activePage === "features") {
      this.selectedFeatures = items;
    } else if (this.activePage === "innovations") {
      this.selectedInnovations = items;
    }
  }

  @computed get selectedItems(): TreeItem[] {
    if (this.activePage === "features") {
        return this.selectedFeatures;
    } else if (this.activePage === "innovations") {
        return this.selectedInnovations;
    }
  }
}
